export const Images = {
  strat: require('./resources/strat.png'),
  cata: require('./resources/cata.png'),
  logo: require('./resources/logo.png'),
  noProductSymbol: require('./resources/noProductSymbol.png'),
  noEventPageSymbol: require('./resources/no_event.png'),
  art: require('./resources/categories/art.png'),
  carbon: require('./resources/categories/carbon.png'),
  clothing: require('./resources/categories/clothing.jpg'),
  realEstate: require('./resources/categories/real_estate.png'),
  metals: require('./resources/categories/metals.png'),
  collectibles: require('./resources/categories/collectibles.jpg'),
  blockapps_cover: require('./resources/categories/blockapps_cover.png'),
  membership: require('./resources/categories/membership.jpg'),
  category1: require('./resources/categories/category1.png'),
  category2: require('./resources/categories/category2.png'),
  category3: require('./resources/categories/category3.png'),
  category4: require('./resources/categories/category4.png'),
  category5: require('./resources/categories/category5.png'),
  category6: require('./resources/categories/category6.png'),
  topSelling1: require('./resources/topSelling/topSelling1.png'),
  topSelling2: require('./resources/topSelling/topSelling2.png'),
  topSelling3: require('./resources/topSelling/topSelling3.png'),
  hero: require('./resources/hero.png'),
  hero2: require('./resources/carbon.jpg'),
  orderImg1: require('./resources/orderImages/orderImg1.png'),
  orderImg2: require('./resources/orderImages/orderImg2.png'),
  orderImg3: require('./resources/orderImages/orderImg3.png'),
  'role-request': require('./resources/request-role.png'),
  newLogo: require('./resources/newLogo.png'),
  marketplaceLogo: require('./resources/marketplace.png'),
  carousel_first: require('./resources/carousel_first_image.png'),
  carousel_second: require('./resources/carousel_second_image.png'),
  carousel_third: require('./resources/carousel_third_image.png'),
  'Carbon-category': require('./resources/categories/Carbon_category.png'),
  'Art-category': require('./resources/categories/Art_category.png'),
  'Clothing-category': require('./resources/categories/Clothing_category.png'),
  'Material-category': require('./resources/categories/Material_category.png'),
  Verified: require('./resources/topSelling/verifiedIcon.png'),
  RemoveIcon: require('./resources/Remove.png'),
  CancelIcon: require('./resources/Cancel.png'),
  Dropdown: require('./resources/DropDown.png'),
  Cart: require('./resources/Cart.png'),
  Backward: require('./resources/Backward.png'),
  Forward: require('./resources/Forward.png'),
  AddBlack: require('./resources/AddBlack.png'),
  CreateInventory: require('./resources/createInventory.png'),
  ForwardIcon: require('./resources/Forward.png'),
  Metal: require('./resources/categories/metal.png'),
  Metal_card: require('./resources/categories/metal_card.png'),
  art_card: require('./resources/categories/art_card.png'),
  tokens_card: require('./resources/categories/tokens.jpg'),
  carbon_card: require('./resources/categories/carbon_card.png'),
  membership_card: require('./resources/categories/membership_card.jpg'),
  menu_icon: require('./resources/Ham_menu.png'),
  Responsive_search: require('./resources/Responsive_Search.png'),
  Responsive_cart: require('./resources/Responive_Cart.png'),
  Header_cart: require('./resources/Header_Cart.png'),
  Header_Search: require('./resources/Search.png'),
  Setting_icon: require('./resources/Setting.png'),
  filter: require('./resources/Filter.png'),
  Remove_filter: require('./resources/remove-filter.png'),
  calender: require('./resources/Calendar.png'),
  logo_white: require('./resources/logo_white.png'),
  banner_arrow: require('./resources/banner_arrow.png'),
  carousel_arrow: require('./resources/carouselArrow.png'),

  StakingX394: require('./resources/Banner/StakingX394.png'),
  StakingX768: require('./resources/Banner/StakingX768.png'),
  StakingX1440: require('./resources/Banner/StakingX1440.png'),
  StakingX1600: require('./resources/Banner/StakingX1600.png'),

  BatmanX394: require('./resources/Banner/BatmanX394.png'),
  BatmanX768: require('./resources/Banner/BatmanX768.png'),
  BatmanX1440: require('./resources/Banner/BatmanX1440.png'),
  BatmanX1600: require('./resources/Banner/BatmanX1600.png'),

  RickAndMortyX394: require('./resources/Banner/RickAndMortyX394.png'),
  RickAndMortyX768: require('./resources/Banner/RickAndMortyX768.png'),
  RickAndMortyX1440: require('./resources/Banner/RickAndMortyX1440.png'),
  RickAndMortyX1600: require('./resources/Banner/RickAndMortyX1600.png'),

  CollectiblesX394: require('./resources/Banner/CollectiblesX394.png'),
  CollectiblesX768: require('./resources/Banner/CollectiblesX768.png'),
  CollectiblesX1440: require('./resources/Banner/CollectiblesX1440.png'),
  CollectiblesX1600: require('./resources/Banner/CollectiblesX1600.png'),

  ClothingX394: require('./resources/Banner/ClothingX394.png'),
  ClothingX768: require('./resources/Banner/ClothingX768.png'),
  ClothingX1440: require('./resources/Banner/ClothingX1440.png'),
  ClothingX1600: require('./resources/Banner/ClothingX1600.png'),

  MetalX394: require('./resources/Banner/MetalX394.png'),
  MetalX768: require('./resources/Banner/MetalX768.png'),
  MetalX1440: require('./resources/Banner/MetalX1440.png'),
  MetalX1600: require('./resources/Banner/MetalX1600.png'),

  TokenX394: require('./resources/Banner/TokenX394.png'),
  TokenX768: require('./resources/Banner/TokenX768.png'),
  TokenX1440: require('./resources/Banner/TokenX1440.png'),
  TokenX1600: require('./resources/Banner/TokenX1600.png'),

  HowX394: require('./resources/Banner/HowX394.png'),
  HowX768: require('./resources/Banner/HowX768.png'),
  HowX1440: require('./resources/Banner/HowX1440.png'),
  HowX1600: require('./resources/Banner/HowX1600.png'),

  LiquidGoldX394: require('./resources/Banner/LiquidGoldX394.jpg'),
  LiquidGoldX768: require('./resources/Banner/LiquidGoldX768.jpg'),
  LiquidGoldX1440: require('./resources/Banner/LiquidGoldX1440.jpg'),
  LiquidGoldX1600: require('./resources/Banner/LiquidGoldX1600.jpg'),

  Icon_1: require('./resources/Banner/banner_icon_1.png'),
  Icon_2: require('./resources/Banner/banner_icon_2.png'),
  Icon_3: require('./resources/Banner/banner_icon_3.png'),
  Icon_4: require('./resources/Banner/banner_icon_4.png'),
};
